export const productDynamicValues: Record<string,string> = {
  'İçerik Sayısı': 'totalContentCount',
  'İçerik Süresi': 'totalContentDuration',
  'İnteraktif İçerik Süresi': 'totalInteractiveContentDuration',
  'İnteraktif Video Sayısı': 'totalInteractiveVideoCount',
  'Konu Sayısı': 'totalTopicCount',
  'Soru Sayısı': 'totalItemCount',
  'Test Sayısı': 'totalTestCount',
  'Ünite Sayısı': 'totalUnitCount',
  'Video Çözümlü Soru Sayısı': 'totalItemCount', // Instead of vas count
  'Video Sayısı': 'totalContentCount', // İçerik Sayısı ile aynı
  'Video Ardı Soru Sayısı': 'totalVasCount', // Instead of video count
};

export const productSpecialButtons: Record<string,string> = {
  'Çözücü': 'SOLVER',
  'Şimdi Anladım': 'GOT_IT',
  'Koçum Yanımda': 'COACH',
  'Online Eğitim': 'ONLINE_EDUCATION'
};

export const ignoreList: any[] = [
  'Non-Error',
  'CustomEvent',
  'InvalidStateError',
  'anonymous',
  'tracking',
  'react-masonry',
  'getItem',
  '(type=error) captured as promise rejection',
  'Failed to read the \'localStorage\'',
  'Cannot read properties of undefined (reading \'domInteractive\')',
  'domInteractive',
  'Cannot read properties of undefined (reading \'banner.text\')',
  'banner.text',
  'Cannot read properties of null (reading \'__transferData\')',
  '__transferData',
  'Cannot read properties of null (reading \'removeItem\')',
  'removeItem',
  'The request is not allowed by the user agent or the platform',
  'Unexpected token',
  /CookieSpool/i,
  /banner\.text/i,
  'checkPerfReady',
  'globalThis',
  'Connection closed',
  /^0$/,
  /^Error: 0$/,
  'Failed to fetch',
  'Load failed',
  'The operation is insecure',
  'The operation was aborted',
  'JSONP request failed',
  'Java object is gone',
  'vazgeçildi',
  '%Object%',
  'Cannot read properties of null (reading \'getElementsByTagName\')',
  'Java bridge method invocation error',
  '"undefined" is not valid JSON',
  'mjs.statWebViewLoadSize',
  'Failed to execute \'exitFullscreen\'',
  'javacalljs is not defined',
  'javacalljswithargs is not defined',
  'SafeDKWebAppInterface',
  'Method not found',
  'ReadableStream',
  'IFrameMessageHandler',
  'XHRInterface',
  'Parameter `frame`',
  'jsReceiveMessages',
  'Cannot read properties of null',
  'Hydration',
  '<unknown>',
  'sendBeacon',
  'not a function',
  'invalid state',
  'UnhandledRejection',
  'selectNode',
  'undefined is not an object',
  'captured as exception',
];
